import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="calendar"
export default class extends Controller {
  static targets = ["day"];

  clickLink(event) {
    this.clearDayTargets();
    event.currentTarget
      .querySelector("time")
      .classList.add("bg-gray-900", "font-semibold", "text-white");
  }

  clearDayTargets() {
    this.dayTargets.forEach((dayButton) => {
      dayButton.classList.remove("bg-gray-900");
      dayButton.classList.remove("font-semibold");
      dayButton.classList.remove("text-white");
    });
  }
}
